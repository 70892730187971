<template>
    <div>
        <van-row class="logo">
            <van-col span="12" offset="6" class="logoRow">
                <van-image width="2.5rem" class="logoImg" :src="require('@/assets/activity/nhdl/logo.png')" />
            </van-col>
        </van-row>

        <van-row type="flex" justify="end" class="user-info">
            <div class="custom-title">{{ username }}</div>
            <van-button plain type="primary" class="logonButton" @click="showLoginPopup"
                v-show="showLoginButton">登&nbsp;&nbsp;录</van-button>
            <van-button plain type="primary" class="logonButton" @click="handleLogout"
                v-show="!showLoginButton">退&nbsp;&nbsp;出</van-button>
        </van-row>

        <van-popup class="login-popup" closeable close-icon="close" round v-model="showLogin">
            <van-row class="login-title">
                <van-col span="24" style="font-size:0.18rem;text-align:center;">玩家登录</van-col>
            </van-row>
            <van-form @submit="onSubmitLogin">
                <van-field v-model="account" name="用户名" label="用户名" placeholder="用户名" clearable :rules="[{ required: true, message: '请填写用户名' },
                { pattern: account_pattern, message: '用户名长度 4-20 位' }]" />

                <van-field v-model="password" name="密码" label="密码" placeholder="密码" clearable :rules="[{ required: true, message: '请填写密码' },
                { pattern: account_pattern, message: '密码长度 6-20 位' }]" />

                <van-button class="loginButton" round block type="info" native-type="submit">
                    登&nbsp;&nbsp;录
                </van-button>
            </van-form>
        </van-popup>

        <van-cell-group style="margin-top:0.4rem" v-for="(item, index) in data.list" :key="index">
            <van-cell v-for="(gift, gift_index) in item.game_giftpacks" :key="gift_index">
                <template #title>
                    <van-image width="0.67rem" height="0.64rem" style="float:left"
                        :src="require('@/assets/activity/ghlc/gift.png')" />
                    <div class="title gift">{{ gift.show_giftpack_name }}</div>
                    <div class="content gift">{{ gift.game_prop_names }}</div>
                </template>

                <template #right-icon>
                    <van-button type="info" size="small" class="giftButton" @click="handlePick(gift.id, gift_index, index)"
                        v-show="!gift.cdkey">领取</van-button>
                    <van-button type="info" size="small" class="giftButton" @click="handleShowCdkey(gift.cdkey)"
                        v-show="gift.cdkey">查看</van-button>
                </template>
            </van-cell>

            <van-cell v-if="item.count > 3" @click="handleGetMore(item.id, index, $event)">
                <p class="more">查看更多礼包({{ item.count - 3 }})<van-icon name="arrow-down" /></p>
            </van-cell>
        </van-cell-group>
        <van-dialog v-model="dialogCdkeyShow" class="giftCode" title="领取成功" confirm-button-text="复制" :message="dialog_cdkey"
            :before-close="beforeClose">
            <!-- <span class="dialog_content"><strong>{{ dialog_cdkey }}</strong></span> -->
        </van-dialog>
    </div>
</template>
  
<script>
import { gameGiftPackDaJinSimple, gameGiftpackDaJinList, login, pick } from "@/api/gameGiftpack"
import { logout } from "@/api/login"
import { Toast } from 'vant';
import { getToken, removeToken, getUsername, setUsername, removeUsername } from '@/utils/auth';
export default {
    data() {
        return {
            username: '游戏玩家',
            showLoginButton: true,
            showLogin: false,
            data: {
                list: []
            },
            account: undefined,
            password: undefined,
            account_pattern: /^\w{4,20}$/,
            dialogCdkeyShow: false,
            dialog_cdkey: undefined,
            osList: { ios: '苹果', android: '安卓' },
            giftButton: false,
            pay_key: '7c03e1358b134fff8f726d7c24f5e46b'
        };
    },
    created() {
        this.getSimple()
        this.loginState()
    },
    methods: {
        //登录状态查询
        loginState() {
            if (getToken('nhdl_')) {
                this.username = getUsername('nhdl_')
                this.showLoginButton = false
            }
        },
        showLoginPopup() {
            this.showLogin = true
        },
        // 登录
        onSubmitLogin() {
            // const that = this
            Toast.loading({
                message: '登录中，请稍等!',
                forbidClick: true
            })
            login({ username: this.account, password: this.password }).then(response => {
                // this.showLogin = false
                // this.showLoginButton = false
                // this.username = response.account
                setUsername(response.account, 'nhdl_')
                // this.$router.go(0)
                location.reload()
            }).catch(() => {

            })
        },
        handleLogout() {
            logout().then(response => {
                console.log(response)
                removeToken('nhdl_')
                removeUsername('nhdl_')
                // this.$router.go(0)
                location.reload()
            }).catch(() => {

            })
        },
        // 领取
        handlePick(id, gift_index, index) {
            if (!getToken('nhdl_')) {
                this.showLogin = true
            } else {
                pick({ id: id }).then(response => {
                    this.dialog_cdkey = response.cdkey
                    this.dialogCdkeyShow = true
                    // 按钮切换为查看
                    this.data.list[index]['game_giftpacks'][gift_index]['cdkey'] = response.cdkey
                }).catch(() => { })
            }
        },
        // 查看
        handleShowCdkey(cdkey) {
            this.dialogCdkeyShow = true
            this.dialog_cdkey = cdkey
        },
        // 查看更多
        handleGetMore(game_id, index) {
            let params = { 'game_id': game_id, 'pay_key': this.pay_key }
            if (getToken()) {
                params['account'] = getUsername('nhdl_')
            }
            gameGiftpackDaJinList(params).then(response => {
                this.data.list[index]['game_giftpacks'] = response
                this.data.list[index]['count'] = false
                console.log(response)
            }).catch(() => {
            })
        },
        getSimple() {
            let params = { pay_key: this.pay_key }
            if (getToken('nhdl_')) {
                params['account'] = getUsername('nhdl_')
            }
            console.log(params)
            gameGiftPackDaJinSimple(params).then(response => {
                this.data.list = response
            }).catch(() => {
            })
        },
        beforeClose(action, done) {
            console.log(action)
            if (action === 'confirm') {
                var oInput = document.createElement('input'); //创建一个隐藏input（重要!）
                oInput.value = this.dialog_cdkey;    //赋值
                document.body.appendChild(oInput);
                oInput.select(); // 选择对象
                document.execCommand("Copy"); // 执行浏览器复制命令
                oInput.className = 'oInput';
                oInput.style.display = 'none';
                console.log('复制成功')
            }
            done()
        },
    }
}
</script>
  
  
<style scoped>
.logo {
    height: 1.28rem;
}

.logoRow {
    height: 100%;
}

.logoImg {
    margin: 0 auto;
}

.user-info {
    margin-right: 0.1rem;
}

.custom-title {
    font-size: 0.18rem;
    font-family: SimHei;
    font-weight: 400;
    color: #202020;
    width: 1rem;
    height: 0.3rem;
    line-height: 0.3rem;
}

.logonButton {
    width: 0.88rem;
    height: 0.3rem;
    font-size: 0.16rem;
    font-family: SimHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 0.3rem;
    /*background: linear-gradient(93deg, #FFD584, #DC9204);*/
    background: crimson;
    border-radius: 1.1rem;
    border: none;
}

.login-popup {
    width: 88%;
    font-size: 0.18rem;
    font-family: SimHei;
    font-weight: 400;
}

.login-title {
    height: 65px;
    text-align: center;
    line-height: 0.65rem;
    font-size: 0.18rem;
}

.loginButton {
    width: 88%;
    text-align: center;
    margin: 0.2rem;
    height: 0.4rem;
    font-size: 0.18rem;
    font-family: SimHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 0.3rem;
    /*background: linear-gradient(93deg, #FFD584, #DC9204);*/
    background: crimson;
    border-radius: 0.2rem;
    border: none;
}

.gift {
    width: 60%;
    float: left;
    margin-left: 0.1rem;
}

.title {
    width: 1.25rem;
    height: 0.3rem;
    font-size: 0.18rem;
    font-family: SimHei;
    font-weight: 400;
    color: #202020;
    line-height: 0.39rem;
}

.content {
    width: 1.8rem;
    height: 0.2rem;
    font-size: 0.14rem;
    font-family: SimHei;
    font-weight: 400;
    color: #CBCBCB;
    line-height: 0.2rem;
}

.giftButton {
    width: 0.8rem;
    height: 0.3rem;
    border: none;
    /*background: linear-gradient(93deg, #FFD584, #DC9204);*/
    background: crimson;
    border-radius: 0.2rem;
    font-size: 0.14rem;
    font-family: SimHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 0.3rem;
    margin-top: 0.14rem;
}

.giftCode {
    width: 88%;
    text-align: center;
    margin: 0.2rem auto;
    font-size: 0.18rem;
    font-family: SimHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 0.3rem;
    /*background: linear-gradient(93deg, #FFD584, #DC9204);*/
    background: crimson;
    border-radius: 0.2rem;
    border: none;
}
</style>
  